import DocsetMenu from "../../../../source/components/docset-menu";
import { MenuWrapper } from "../../../../source/components/menu";
import WorkoutMenuItem from "../../../../source/components/workout-menu-item";
import { Button } from '@apollo/space-kit/Button';
import { IconRobot1 } from '@apollo/space-kit/icons/IconRobot1';
import { Link } from 'gatsby';
import { colors } from 'gatsby-theme-apollo-core';
import * as React from 'react';
export default {
  DocsetMenu,
  MenuWrapper,
  WorkoutMenuItem,
  Button,
  IconRobot1,
  Link,
  colors,
  React
};