import React from 'react';
// export {ReactComponent as MobileLogo} from '../../assets/logo-icon.svg';

import logo from '../../assets/logo-icon.svg';
export const MobileLogo = () => {
  const style = {
    width: '32px',
    height: '32px',
    marginBottom: '0',
  }
  return <img src={logo} style={style} alt="stripe-express doc logo" />
}